.App {
  text-align: center;
  max-height: 100%;
  overflow-x: hidden;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/*
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
*/

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.HeaderBar {
  height: 5em;
  border-bottom: 0.2px solid grey;
  padding-left: 0.1em;
  padding-right: 0.1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fbfaf4; /*lighter - #f5f2eb , darker-> f5f0e4 */
}

.HeaderBar img {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  margin-left: 0.3em;
  width: 7em;
  height: 4.2em;
}

.HeaderBar h2 {
  margin-top: 1em;
}

.Login {
  margin-top: 1em;
  margin-right: 1em;
}

.LoggedIn {
  margin-top: 1em;
  margin-right: 1em;
  display: flex;
  gap: 0.2em;

}

.InfoCard h2 {
  max-height: 4em;
}

.InfoCard {
  max-height: 90vh;
  width: 50%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  background-color: #fbfaf4;
}

.InfoCardHeader {
  margin-top: 0.2em;
  min-width: 90%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
}


.InfoShow {
  display: flex;
  flex-direction: column;
}

.InfoHide {
  background-color: red;
  display: none;
}

.InfoBar {
  max-width: 95%;
  display: flex;
  flex-direction: column;
}

.InfoBar li {
  text-align: left;
  max-width: 90%;
}

.InfoBar h2, h3 {
  text-align: left;
  margin-left: 1em;
}

.markerLabels {
  margin-bottom: 0.3em;
}

.PictureCard {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 15em;
  max-width: 80%;
  margin: auto;
}

.PictureCard button {
  background-color: transparent;
  border: none;
  font-size: 2em;
}

.PictureIndex {
  position: absolute;
  z-index: 2;
  top: 5%;
  left: 80%;
}

.parcelImage {
  max-width: 80%;
  max-height: 100%;
}

.ParcelCardWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.65em;
  justify-content: center;
}

.ParcelCard {
  border: 1px solid black;
  border-radius: 0.2em;
  width: 15em;
  height: 15em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fbfaf4; /*lighter -> #bbe3c6, darker -> #95d2a5 */
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.ParcelCard:hover {
  box-shadow: rgba(60, 64, 67, .6) 0 1px 3px 0, rgba(60, 64, 67, .3) 0 4px 8px 3px;
}

.ParcelCard img {
  max-height: 75%;
  max-width: 100%;
}

.ParcelCardInfo {
  display: flex;
  justify-content: flex-start;
  background-color: #bbe3c6;
  height: 25%;
}

.ParcelCardInfo p {
  font-size: 0.75em;
  font-weight: 500;
  color: #3c4043;
}

.mapCard {
  display: flex;
  flex-direction: row;
}

.highlightedMarker {
  border: 1px solid red;
  width: 20em;
}

.FiltersHide {
  display: none;
}

.FiltersShow{
  z-index: 10;
  min-height: 2.5em;
  min-width: 56vw;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  transform: translateX(-30vw);
  background-color: white;
}

.FilterHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 3.5em;
  padding: 0.5em;
}

.FilterHeader h3 {
  margin-left: -2em;
}

.FilterResultsCount {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5em;
}

.FilterItem {
  text-align: left;
  border-top: 0.2px solid grey;
  border-bottom: 0.2px solid grey;
  padding-left: 0.5em;
}

.FilterItem h3 {
  margin-bottom: 1.75em;
}

.FormGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.FilterFooter {
  display: flex;
  justify-content: flex-end;
  padding: 0.5em;
}

.ModalBackground {
  width: 100vw;
  height: 100vh;
  z-index: 5;
  position: absolute;
  background: grey;

}

.ModalCard {
  width: 75vw;
  height: 75vh;
  background: white;
  border: 1px solid #ccc;
  transition: 1.1s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
  filter: blur(0);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  z-index: 10;
  position: absolute;
  margin: auto;
  display: flex;
}

.ModalMap {
  height: 90%;
}

.Modal-hidden {
  visibility: hidden;
}

.ParcelPageWrapper {
  max-width: 85%;
  margin: auto;
}

.ScoreCard-Card {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 0.5em;
}

.ScoreCard {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 0.5em;
  width: 35%;
}

.ScoreCard-Cell {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.ScoreCard-Cell h4 {
  margin-bottom: -.8em;
}

.ScoreCard-Cell p {
  white-space: pre;
}

.AuctionBox {
  width: 25%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.AuctionBox h4 {
  margin-bottom: -.8em;
}

.AuctionBox p {
  margin-bottom: -.2em;
}

.Description {
  margin-bottom: 0.3em;
}



@media (max-width: 500px) {

  p {
    font-size: .8em;
  }

  .HeaderBar h2 {
  font-size: 1.2em;
}

  .mapCard {
    display: flex;
    flex-direction: column;
  }

  .InfoCardHeader {
    padding-left: 0;
    padding-right: 0.5em;
    width: 95%;
  }

/* This allows the user to scroll past the map */
  .InfoCard {
  overflow-y: visible;
  width: 100%;
  }


  .ParcelCard {
    width: 20em;
    height: 20em;
  }

  .FiltersShow{
    min-height: 2.5em;
    min-width: 100vw;
    flex-direction: column;
    transform: translateX(0);
    position: absolute;
    top: 20vh;
    background-color: white;
  }

  .ParcelPageWrapper {
  max-width: 95%;
  margin: auto;
}

.ScoreCard-Card {
  max-width: 95%;
  justify-content: space-around;
  margin: auto;
  gap:0.1em;
}

.AuctionBox a {
  margin-left: -5em;
}

}

.button-18 {
background-color: #a0e1c0;
/*background-image: url("/landscape1.jpg");*/
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}

/* CSS */
.button-17 {
  align-items: center;
  appearance: none;
  background-color: #ffffff; /* f5f0e4 light grey*/
  border-radius: 1em;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 2.5em;
  justify-content: center;
  letter-spacing: 0.1em;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 0.5em 1em;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  z-index: 0;
}

.button-17:hover {
  background: #F6F9FE;
  color: #174ea6;
}

.button-17:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.button-17:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.button-17:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

.button-17:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

.button-17:disabled {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

